import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-broken-arrow-oklahoma.png'
import image0 from "../../images/cities/scale-model-of-rose-district-fountain-in-broken-arrow-oklahoma.png"
import image1 from "../../images/cities/scale-model-of-broken-arrow-historical-soc-in-broken-arrow-oklahoma.png"
import image2 from "../../images/cities/scale-model-of-broken-arrow-museum-in-broken-arrow-oklahoma.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Broken Arrow'
            state='Oklahoma'
            image={image}
            lat='36.060949'
            lon='-95.79745259999999'
            attractions={ [{"name": "Rose District Fountain", "vicinity": "416 S Main St, Broken Arrow", "types": ["point_of_interest", "establishment"], "lat": 36.0488243, "lng": -95.7910134}, {"name": "Broken Arrow Historical Soc", "vicinity": "400 S Main St, Broken Arrow", "types": ["museum", "store", "point_of_interest", "establishment"], "lat": 36.0492032, "lng": -95.7911229}, {"name": "Broken Arrow Museum", "vicinity": "400 S Main St, Broken Arrow", "types": ["museum", "point_of_interest", "establishment"], "lat": 36.0492786, "lng": -95.79098160000001}] }
            attractionImages={ {"Rose District Fountain":image0,"Broken Arrow Historical Soc":image1,"Broken Arrow Museum":image2,} }
       />);
  }
}